import React from 'react';

function Town() {
    return (
        <div className="town">
            <h2>Welcome to town</h2>
            <p>Welcome to town.  The sun is shining and you have your whole life ahead of you.  How will you proceed?</p>
        </div>
    )

}

export default Town;